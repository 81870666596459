const GETTERS = {
  showSearchBar(state, getters, rootState) {
    const { auth: { user } } = rootState

    if (!user) return false

    return true
  },
  isSearchUser(state, getters, rootState) {
    const { auth: { user } } = rootState

    if (!user) return false

    const { access: { role } } = user

    const internals = ['internal', 'tester']

    return internals.includes(role)
  },
  showLayoutBar(state, getters, rootState, rootGetters) {
    const { tools: { reportName } } = rootState

    if (!reportName) return false

    const currentReportItems = rootGetters['tools/currentReportItems']
    const isReportRoute = rootGetters['tools/isReportRoute']
    const haveData = currentReportItems || false

    return isReportRoute && !!haveData
  },
  showLegendBar(state, getters, rootState, rootGetters) {
    const { tools: { reportName } } = rootState

    if (!reportName) return false

    const reportKey = rootGetters['tools/reportKey']
    const currentReportItems = rootGetters['tools/currentReportItems']
    const isLegendRoute = ['discrepancy', 'pacing'].includes(reportKey)

    return isLegendRoute && !!currentReportItems
  },
  showExportBar(state, getters, rootState, rootGetters) {
    const { tools: { reportName } } = rootState

    if (!reportName) return false

    const isInternalRoute = rootGetters['tools/isInternalRoute']
    const currentReportItems = rootGetters['tools/currentReportItems']
    const isReportRoute = rootGetters['tools/isReportRoute']

    const isExportRoute = isReportRoute || isInternalRoute
    const haveData = currentReportItems || false

    return isExportRoute && !!haveData
  },
  showFilterBar(state, getters, rootState, rootGetters) {
    const reportKey = rootGetters['tools/reportKey']
    const currentReportItems = rootGetters['tools/currentReportItems']
    const isReportRoute = rootGetters['tools/isReportRoute']

    const excludedRoutes = ['time']
    const isFilterRoute = isReportRoute && excludedRoutes.indexOf(reportKey) === -1
    const haveData = currentReportItems || false

    return isFilterRoute && !!haveData
  },
  showDataFreshness(state, getters) {
    const { showSearchBar } = getters

    if (!showSearchBar) return false

    return true
  },
}

export default GETTERS
